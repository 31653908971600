<template>
	<div class="app-container">
		<!-- tab切换 -->
		<el-tabs v-model="type" @tab-click="getAfterSalesReasons">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
		</el-tabs>
		<div class="filter-container">
			<div class="filter-item">
				<button-permissions :datas="'addAfterSalesReasons'">
					<el-button type="primary" style="width:100px;" @click="add">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="navigationList" style="width: 100%;margin-bottom: 20px;" row-key="id" border>
				<el-table-column prop="reason" label="名称" width="320"></el-table-column>
				<el-table-column prop="sort" label="排序"></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column prop="address" label="操作" width="320">
					<template slot-scope="scop">
						<div style="display:flex;">
							<button-permissions :datas="'updateAfterSalesReasons'">
								<div class="table-button" @click="edit(scop.row)">编辑</div>
							</button-permissions>
							<button-permissions :datas="'delAfterSalesReasons'">
								<div class="table-button" style="color:#F56C6C" @click="deleteItem(scop.row.reasonId)">删除</div>
							</button-permissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-pagination
			v-if="total"
			style="margin:20px 0;float:right;"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageNo"
			:page-sizes="[10, 20, 30, 40, 50]"
			:page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="total"
		></el-pagination>
		<!-- 新增/编辑弹框 -->
		<el-dialog :visible.sync="jurVisable" width="50%" :title="jurTitle" :show-close="false">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form
					:model="ruleForm"
					ref="ruleForm"
					label-width="140px"
					class="demo-ruleForm"
					:validate-on-rule-change="false"
				>
					<el-form-item
						:label="listStateList[this.type-1].value+'名称:'"
						prop="reason"
						:rules="[{required:true,message:'名称不能为空',trigger: 'blur'}]"
					>
						<el-input
							v-model="ruleForm.reason"
							:placeholder="'请输入' +listStateList[this.type-1].value+ '名称'"
							clearable
							style="width:300px"
						></el-input>
					</el-form-item>
					<el-form-item
						label="排序:"
						prop="sort"
						:rules="[{required:true,message:'排序不能为空',trigger: 'blur'}]"
					>
                        <el-input
                            v-model="ruleForm.sort"
                            style="width:100px;"
                            onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
                        ></el-input>
					</el-form-item>
				</el-form>
			</div>
			<!-- 按钮区域 -->
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="jurVisable=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveChange()" :loading="loading">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	addAfterSalesReasons,
	delAfterSalesReasons,
	getAfterSalesReasons,
	updateAfterSalesReasons
} from '@/api/goods.js'
import buttonPermissions from '@/components/buttonPermissions';
export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			navigationList: [],  //广告位置列表数据
			ruleForm: {
				reasonId: '',
				reason: '',
				sort: '',
				type: '',
			},
			jurTitle: '',    //弹窗名称
			jurVisable: false,   //弹窗开关
			loading: false,  //加载
			type: '1',   //原因类型
			listStateList: [
				{
					id: 1,
					value: '退款原因',
				},
				{
					id: 2,
					value: '退货退款原因',
				},
				{
					id: 3,
					value: '换货原因',
				},
				{
					id: 4,
					value: '订单取消原因',
				},
			],
			pageNo: 1,
			pageSize: 20,
			total: 3,
		};
	},
	created () {
		this.getAfterSalesReasons();
	},
	methods: {
		//获取列表
		async getAfterSalesReasons () {
			let data = {
				pageNo: this.pageNo,
				pageSize: this.pageSize,
				type: this.type
			}

			let res = await getAfterSalesReasons(data);
			this.navigationList = res.data.records;
			this.total = res.data.total;
		},
		//添加
		add () {
			this.jurTitle = "新增" + this.listStateList[this.type - 1].value;
			this.ruleForm.reasonId = '';
			this.ruleForm.reason = '';
			this.ruleForm.sort = '';
			this.ruleForm.type = this.type;
			this.jurVisable = true;
		},
		//编辑
		edit (item) {
			this.jurTitle = "编辑" + this.listStateList[this.type - 1].value;
			this.ruleForm.reasonId = item.reasonId;
			this.ruleForm.reason = item.reason;
			this.ruleForm.sort = item.sort;
			this.ruleForm.type = this.type;
			this.jurVisable = true;
		},
		//删除
		deleteItem (id) {
			console.log(id);
			this.$confirm('删除后将无法恢复, 是否继续?', '删除菜单', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let result = await delAfterSalesReasons({
					reasonId: id
				});
				if (result.success) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				} else {
					this.$message({
						type: 'error',
						message: result.alertMsg
					});
				}
				this.getAfterSalesReasons();
			}).catch(() => {
				console.log('取消删除');
			});
		},
		//弹窗确定按钮
		async saveChange () {
			var _this = this;
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					try {
						this.loading = true;

						let result = {};
						if (_this.ruleForm.reasonId) {
							result = await updateAfterSalesReasons(_this.ruleForm);
						} else {
							result = await addAfterSalesReasons(_this.ruleForm);
						}

						if (result.success) {
							_this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功!'
							});
							_this.jurVisable = false;
							_this.getAfterSalesReasons();
						} else {
							_this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg
							});
						}
						this.loading = false
					} catch (error) {
						console.log(error);
						this.loading = false
					}
				} else {
					return false;
				}
			});
		},
		// 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getAfterSalesReasons();
		},
		// 翻页
		handleCurrentChange (val) {
			console.log(`当前页: ${val}`);
			this.pageNo = val;
			this.getAfterSalesReasons();
		},
	},
};
</script>

<style lang="less" scoped>
.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}
</style>